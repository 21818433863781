<template>
  <section>
    <div class="d-flex flex-wrap px-3">
      <template v-for="(orden, index) in ordenes">
        <div :key="index" :class="[{'col-md' : ordenes === 2 }, 'col-custom col-12 col-xl p-0 pt-2 p-sm-2']">
          <div class="row px-4 fs-9 justify-content-between">
            <div class="col-sm pl-0 order-1 order-sm-0">
              <p>
                <span class="bold">{{transportistaSeleccionado ? transportistaSeleccionado.ape_per +' '+ transportistaSeleccionado.nom_per : 'Sin asignar'}}</span>
                <small class="small-block">Transportista</small>
              </p>
              <p>
                <span class="bold">{{vigilanteSeleccionado ? vigilanteSeleccionado.ape_per +' '+ vigilanteSeleccionado.nom_per : 'Sin asignar'}}</span>
                <small class="small-block">Vigilante</small>
              </p>
              <p>
                <span class="bold">{{montacarguistaSeleccionado ? montacarguistaSeleccionado.ape_per +' '+ montacarguistaSeleccionado.nom_per : 'Sin asignar'}}</span>
                <small class="small-block">Montacarguista</small>
              </p>
              <p>
                <span class="bold">{{almacenistaSeleccionado ? almacenistaSeleccionado.ape_per +' '+ almacenistaSeleccionado.nom_per : 'Sin asignar'}}</span>
                <small class="small-block">Almacenista</small>
              </p>
              <p>
                <span class="bold">{{datosEncabezado.vendedores}}</span>
                <small class="small-block">Vendedores</small>
              </p>
            </div>
            <div class="col-sm px-0 text-sm-right">
              <p class="titulo-impresion m-0">Orden de Salida</p>
              <p v-if="fechaSalida">
                <span class="bold">N° {{ datosEncabezado.salida }}</span>
                <small class="small-block">Salida de almacén</small>
              </p>
              <p>
                <span class="bold" v-if="fechaRetorno">{{formatearFechaYHora(datosEncabezado.fechaYhora)}}</span>
                <small class="small-block">{{fechaRetorno ? 'Fecha y hora' : 'Sin fecha de retorno asignada'}}</small>
              </p>
              <p>
                <span class="bold">{{datosEncabezado.estados}}</span>
                <small class="small-block">Zonas</small>
              </p>
              <p>
                <span class="bold">{{datosEncabezado.num_suc}}</span>
                <small class="small-block">N° clientes</small>
              </p>
            </div>
            <p v-if="datosEncabezado.obs_des && datosEncabezado.obs_des !== 'null'" class="col-12 p-0">
              <span class="bold">{{datosEncabezado.obs_des}}</span>
              <small class="small-block">Observaciones</small>
            </p>
          </div>
          <ul class="table orden">
            <li class="encabezado sticky">
              <div class="thead claro bold">
                <div class="col-12 col-sm py-print-0">
                  Descripción
                  <span class="small-block">Código</span>
                </div>
                <div class="col-3 text-right py-print-0">Unidades</div>
                <div class="col-3 text-right py-print-0">Kilos</div>
              </div>
            </li>
            <template
              v-for="(producto, index) in productos"
            >
              <li :key="index" class="contenido flex-wrap" v-if="producto.codigo">
                <div v-if="separaciones.estado && producto.est" class="col-12 text-sm-center bold tabla-separador p-sm-1" data-columna="Estado">
                  {{producto.est}}
                </div>
                <div v-if="separaciones.categoria && producto.cat" class="col-12 text-sm-center bold tabla-separador p-sm-1" data-columna="Categoría">
                  {{producto.cat}}
                </div>
                <div v-if="separaciones.vendedor && producto.ven" class="col-12 text-sm-center bold tabla-separador p-sm-1" data-columna="Vendedor">
                  {{producto.ven}}
                </div>
                <div class="col-12 col-sm py-print-0" data-columna="DESCRIPCIÓN - CÓDIGO">
                  <p>
                    {{producto.descripcion}}
                    <span class="small-block">{{producto.codigo}}</span>
                  </p>
                </div>
                <div class="col-12 col-sm-3 text-sm-right py-print-0" data-columna="UNIDADES">
                  {{producto.unidades}}
                </div>
                <div class="col-12 col-sm-3 text-sm-right py-print-0" data-columna="KILOS">
                  {{producto.kilos}}
                </div>
              </li>
              <li :key="index" v-if="separaciones.categoria && producto.totalCanCat" class="contenido claro font-weight-bold">
                <div class="col" data-columna="TOTALES POR CATEGORíA">
                  <span class="d-none d-sm-block text-uppercase">Totales por categoría</span>
                </div>
                <div class="col-12 col-sm-3 text-sm-right" data-columna="UNIDADES">
                  {{producto.totalCanCat}}
                </div>
                <div class="col-12 col-sm-3 text-sm-right" data-columna="KILOS">
                  {{Number(producto.totalPeCat).toFixed(2)}} kg
                </div>
              </li>
              <li :key="index" v-if="separaciones.vendedor && producto.totalCanVen" class="contenido claro font-weight-bold">
                <div class="col" data-columna="TOTALES POR VENDEDOR">
                  <span class="d-none d-sm-block text-uppercase">Totales por vendedor</span>
                </div>
                <div class="col-12 col-sm-3 text-sm-right" data-columna="UNIDADES">
                  {{producto.totalCanVen}}
                </div>
                <div class="col-12 col-sm-3 text-sm-right" data-columna="KILOS">
                  {{Number(producto.totalPeVen).toFixed(2)}} kg
                </div>
              </li>
              <li :key="index" v-if="separaciones.estado && producto.totalCanEst" class="contenido claro font-weight-bold">
                <div class="col" data-columna="TOTALES POR ESTADO">
                  <span class="d-none d-sm-block text-uppercase">Totales por estado</span>
                </div>
                <div class="col-12 col-sm-3 text-sm-right" data-columna="UNIDADES">
                  {{producto.totalCanEst}}
                </div>
                <div class="col-12 col-sm-3 text-sm-right" data-columna="KILOS">
                  {{Number(producto.totalPeEst).toFixed(2)}} kg
                </div>
              </li>
            </template>
            <li class="contenido claro font-weight-bold">
              <div class="col" data-columna="TOTALES">
                <span class="d-none d-sm-block text-uppercase">Totales</span>
              </div>
              <!-- Campos de totales a despachar y pedidos -->
              <div class="col-12 col-sm-3 text-sm-right" data-columna="UNIDADES">
                  {{totalesPorPresentacionPedidos.cantidad}}
              </div>
              <div class="col-12 col-sm-3 text-sm-right" data-columna="KILOS">
                  {{Number(totalesPorPresentacionPedidos.peso).toFixed(2)}} kg
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <footer class="footer-fixed no-print">
      <div class="col-12 row align-items-center justify-content-center">
        <p class="col-12 col-md-auto col-lg-2 col-xl-auto bold text-center m-0">Separar por</p>
        <div class="col-auto bold row align-sm-items-center justify-content-center">
          <div class="col-auto custom-control custom-switch secundario mr-3">
            <input
              v-model="separaciones.estado"
              id="separar-estado"
              type="checkbox"
              class="custom-control-input secundario"
              title="Separar por estado"
              :value="separaciones.estado"
              @change="datosOrdenSalida"
            >
              <label
                class="custom-control-label"
                for="separar-estado"
              >
                Estado
            </label>
          </div>
          <div class="col-auto custom-control custom-switch secundario mr-3">
            <input
              v-model="separaciones.vendedor"
              id="separar-vendedor"
              type="checkbox"
              class="custom-control-input secundario"
              title="Separar por vendedor"
              :value="separaciones.vendedor"
              @change="datosOrdenSalida"
            >
              <label
                class="custom-control-label"
                for="separar-vendedor"
              >
                Vendedor
            </label>
          </div>
          <div class="col-auto custom-control custom-switch secundario mr-3">
            <input
              v-model="separaciones.categoria"
              id="separar-categoria"
              type="checkbox"
              class="custom-control-input secundario"
              title="Separar por categoria"
              :value="separaciones.categoria"
              @change="datosOrdenSalida"
            >
              <label
                class="custom-control-label"
                for="separar-categoria"
              >
                Categoría
            </label>
          </div>
        </div>
        <div class="col col-sm-4 col-lg-4 col-xl-auto text-center">
          <mdb-btn
            flat
            dark-waves
            :icon="orientacionVertical === true ? 'grip-lines-vertical' : 'grip-lines'"
            class="px-0 py-1"
            @click="orientacionVertical = !orientacionVertical"
          >
            <span>{{orientacionVertical === true ? 'Vertical' : 'Horizontal'}}</span>
            <small class="small-block">Orientación</small>
          </mdb-btn>
        </div>
        <div class="col col-sm-4 col-lg-4 col-xl-auto text-center">
          <mdb-btn
            flat
            dark-waves
            :icon="ordenes === 2 ? 'caret-up' : 'caret-down'"
            class="px-0 py-1"
            @click="ordenes === 2 ? ordenes++ : ordenes--"
          >
            Imprimir <span>{{ordenes === 2 ? '3' : '2'}}</span>
          </mdb-btn>
        </div>
        <div class="col col-sm-4 col-lg-4 col-xl-auto text-center">
          <mdb-btn
            color="primario"
            icon="print"
            class="px-3 py-2"
            title="Imprimir tabla actual"
            @click="imprimir"
          >
            <span class="mr-2">Imprimir</span>
          </mdb-btn>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import { mdbBtn } from 'mdbvue'
import { formatearFecha, formatearFechaYHora } from '@/funciones/funciones.js'
import { apiPost } from '@/funciones/api.js'
export default {
  name: 'OrdenDeSalida',
  components: {
    mdbBtn
  },
  data () {
    return {
      ordenes: 2,
      orientacionVertical: false,
      formatearFecha,
      formatearFechaYHora,
      estaCargando: false,
      alertaMensaje: { contenido: '' },
      fechaSalida: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2),
      fechaRetorno: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2),
      vigilanteSeleccionado: {},
      montacarguistaSeleccionado: {},
      almacenistaSeleccionado: {},
      transportistaSeleccionado: {},
      despachoSeleccionado: {},
      separaciones: {
        estado: false,
        categoria: false,
        vendedor: false
      },
      datosPedidos: {},
      datosPedidosIm: {},
      datosEncabezado: {},
      listaTransporte: {
        transportistas: [],
        vehiculos: []
      },
      totalesPorPresentacion: {},
      data: [],
      productos: []
    }
  },
  watch: {
    orientacionVertical: function () {
      const element = document.getElementById('print-portrait')
      if (element !== null) { // Eliminar hoja temporal
        element.remove()
      }

      const head = document.head || document.getElementsByTagName('head')[0]
      const style = document.createElement('style')
      style.setAttribute('id', 'print-portrait')
      style.type = 'text/css'
      style.media = 'print'
      let css = ''

      if (this.orientacionVertical) { // activar portrait
        css = '@page { size: letter portrait; margin: 10mm 0mm 0mm 0mm; }'
      } else { // activar landscape
        css = '@page { size: letter landscape; }'
      }

      style.appendChild(document.createTextNode(css))
      head.appendChild(style) // Crea hoja temporal
    },
    separaciones () {
      this.datosOrdenSalida()
    }
  },
  computed: {
    vendedores () {
      if (!this.data) return {}
      const vendedores = {}
      this.data.ven.forEach(e => {
        if (!vendedores[e.id_per]) {
          vendedores[e.id_per] = {
            id_ven: e.id_ven,
            nom_per: e.nom_per,
            ape_per: e.ape_per,
            id_per_ven: e.id_per
          }
        }
      })
      return vendedores
    },
    presentaciones () {
      if (!this.data) return {}
      const presentaciones = {}
      this.data.pre.forEach((p) => {
        presentaciones[p.id_pre] = p
      })
      return presentaciones
    },
    categorias () {
      if (!this.data) return {}
      const categorias = {}
      const productos = {}
      this.data.cat.forEach((c) => {
        categorias[c.id_cat] = c
        categorias[c.id_cat].productos = {}
      })
      this.data.pro.forEach((p) => {
        productos[p.id_pro] = p
        productos[p.id_pro].presentaciones = {}
      })
      this.data.pre.forEach((p) => {
        productos[p.id_pro_pre].presentaciones[p.id_pre] = p
      })
      for (const p in productos) {
        if (Object.keys(productos[p].presentaciones).length) {
          categorias[productos[p].id_cat_pro].productos[productos[p].id_pro] = productos[p]
        }
      }
      for (const c in categorias) {
        if (!Object.keys(categorias[c].productos).length) {
          delete categorias[c]
        }
      }
      return categorias
    },
    notasPorPedido () {
      if (!this.data) return {}
      const notasPorPedido = {}
      this.data.dnop.forEach((np) => {
        if (!notasPorPedido[np.id_ped_np]) {
          notasPorPedido[np.id_ped_np] = {}
        }
        if (!notasPorPedido[np.id_ped_np][np.id_pre_np]) {
          notasPorPedido[np.id_ped_np][np.id_pre_np] = {}
        }
        if (notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad) {
          notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad += Number(np.can_np)
        } else {
          notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad = Number(np.can_np)
        }
        notasPorPedido[np.id_ped_np][np.id_pre_np].inventario = np.can_ni ? np.can_ni : 0
      })
      return notasPorPedido
    },
    totalesPorPresentacionPedidos () {
      if (!this.data || !this.data.dnop) return {}
      const totales = {
        peso: 0,
        cantidad: 0
      }
      this.data.dnop.forEach((np) => {
        if (this.presentaciones[np.id_pre_np]) {
          if (!totales[np.id_pre_np]) {
            totales[np.id_pre_np] = {
              peso: 0,
              cantidad: 0
            }
          }
          const cantidad = Number(np.can_np)
          const peso = Number((Number(this.presentaciones[np.id_pre_np].pesm_pre) * cantidad).toFixed(2))

          totales[np.id_pre_np].cantidad += cantidad
          totales[np.id_pre_np].peso += peso

          totales.peso += peso
          totales.cantidad += cantidad
        }
      })
      return totales
    }
  },
  methods: {
    async ordenDeSalida () {
      this.datosPedidosIm = {}
      try {
        await this.verDetallesDespacho()
        const personal = {}
        personal.vigilante = this.vigilanteSeleccionado
        personal.montacarguista = this.montacarguistaSeleccionado
        personal.almacenista = this.almacenistaSeleccionado
        personal.transportista = this.transportistaSeleccionado
        await this.datosOrdenSalida()
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un problema obteniendo los detalles del despacho para la orden de salida',
          tipo: 'error'
        }
      }
    },
    verDetallesDespacho () {
      this.datosPedidos = {}
      this.cargaPedido = {}
      this.estaCargando = true
      return new Promise((resolve, reject) => {
        apiPost({ s: 'desEditallesMv' }, 'des=' + this.$route.params.id)
          .then((res) => {
            this.data = res.data
            res.data.peddes.forEach((p) => {
              this.datosPedidos[p.id_ped] = p
              this.datosPedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            })
            res.data.desdet.forEach((p) => {
              this.datosEncabezado.obs_des = p.obs_des
              this.fechaSalida = p.fec_des
              this.fechaRetorno = p.fec_ret_des
              this.vigilanteSeleccionado = {
                id_per: p.v_id_per,
                nom_per: p.v_nom_per,
                ape_per: p.v_ape_per
              }
              this.montacarguistaSeleccionado = {
                id_per: p.m_id_per,
                nom_per: p.m_nom_per,
                ape_per: p.m_ape_per
              }
              this.almacenistaSeleccionado = {
                id_per: p.a_id_per,
                nom_per: p.a_nom_per,
                ape_per: p.a_ape_per
              }
              this.transportistaSeleccionado = {
                id_per: p.t_id_per,
                nom_per: p.t_nom_per,
                ape_per: p.t_ape_per
              }
            })
            res.data.dsap.forEach((p) => {
              if (!this.cargaPedido[p.id_ped_np]) {
                this.cargaPedido[p.id_ped_np] = {}
              }
              if (!this.cargaPedido[p.id_ped_np][p.id_pre_np]) {
                this.cargaPedido[p.id_ped_np][p.id_pre_np] = p.can_sap
              }
              this.calcularTotales()
            })
            resolve()
            this.estaCargando = false
          }).catch(err => {
            this.estaCargando = false
            reject(err)
          })
      })
    },
    calcularTotales () {
      this.deshabilitarBotonCargar = false
      const totales = {
        peso: 0,
        volumen: 0,
        cantidad: 0
      }
      for (const pedido in this.cargaPedido) {
        for (const presentacion in this.cargaPedido[pedido]) {
          if (this.presentaciones[presentacion]) {
            if (!totales[presentacion]) {
              totales[presentacion] = {
                cantidad: 0,
                peso: 0,
                volumen: 0
              }
            }
            const cantidad = Number(this.cargaPedido[pedido][presentacion])
            const volumen = Number((cantidad * Number(this.presentaciones[presentacion].volm_pre)).toFixed(2))
            const peso = Number((cantidad * Number(this.presentaciones[presentacion].pesm_pre)).toFixed(2))
            totales[presentacion].cantidad += cantidad
            totales[presentacion].peso += peso
            totales[presentacion].volumen += Number((cantidad * Number(this.presentaciones[presentacion].vol_pre)).toFixed(2))

            totales.peso += peso
            totales.volumen += volumen
            totales.cantidad += cantidad
          }
        }
      }
      this.totalesPorPresentacion = totales
    },
    datosOrdenSalida () {
      const datosIm = {}
      this.datosPedidosIm = {}
      this.datosEncabezado.salida = this.$route.params.id
      this.datosEncabezado.suc = {}
      this.datosEncabezado.num_suc = 0
      datosIm.zonas = {}
      datosIm.vendedor = {}
      this.datosPedidosIm.cate = {}
      const minutos = ('0' + new Date().getMinutes()).slice(-2)
      const segundos = ('0' + new Date().getSeconds()).slice(-2)
      if (this.fechaRetorno) {
        this.datosEncabezado.fechaYhora = this.fechaSalida + ' ' + new Date().getHours() + ':' + minutos + ':' + segundos
      }
      if (this.datosPedidos) {
        for (const i in this.datosPedidos) {
          datosIm.zonas[this.datosPedidos[i].est_zon] = this.datosPedidos[i].est_zon
          if (!this.datosEncabezado.suc[this.datosPedidos[i].id_suc]) {
            this.datosEncabezado.suc[this.datosPedidos[i].id_suc] = this.datosPedidos[i].id_suc
            this.datosEncabezado.num_suc += 1
          }

          let estados = ''
          let coma = ''
          for (const zona in datosIm.zonas) {
            if (Object.hasOwnProperty.call(datosIm.zonas, zona)) {
              estados += coma + datosIm.zonas[zona]
              coma = ', '
            }
          }
          this.datosEncabezado.estados = estados += '.'

          datosIm.vendedor[this.datosPedidos[i].id_per_v_suc] = this.vendedores[this.datosPedidos[i].id_per_v_suc].nom_per + ' ' + this.vendedores[this.datosPedidos[i].id_per_v_suc].ape_per
          let vendedores = ''
          coma = ''
          for (const vendedor in datosIm.vendedor) {
            if (Object.hasOwnProperty.call(datosIm.vendedor, vendedor)) {
              vendedores += coma + datosIm.vendedor[vendedor]
              coma = ', '
            }
          }
          this.datosEncabezado.vendedores = vendedores += '.'
          if (this.separaciones.estado) {
            if (!this.datosPedidosIm[this.datosPedidos[i].est_zon]) {
              this.datosPedidosIm[this.datosPedidos[i].est_zon] = {}
              this.datosPedidosIm[this.datosPedidos[i].est_zon].est = this.datosPedidos[i].est_zon
            }
            if (!this.datosPedidosIm[this.datosPedidos[i].est_zon][this.datosPedidos[i].id_per_v_suc]) {
              this.datosPedidosIm[this.datosPedidos[i].est_zon][this.datosPedidos[i].id_per_v_suc] = {}
              this.datosPedidosIm[this.datosPedidos[i].est_zon][this.datosPedidos[i].id_per_v_suc].ven = this.vendedores[this.datosPedidos[i].id_per_v_suc].nom_per + ' ' + this.vendedores[this.datosPedidos[i].id_per_v_suc].ape_per
            }
            for (const n in this.datosPedidos[i].notas) {
              if (this.datosPedidosIm[this.datosPedidos[i].est_zon][this.datosPedidos[i].id_per_v_suc][n]) {
                this.datosPedidosIm[this.datosPedidos[i].est_zon][this.datosPedidos[i].id_per_v_suc][n] += Number((this.datosPedidos[i].notas[n].cantidad).toFixed(2))
              } else {
                this.datosPedidosIm[this.datosPedidos[i].est_zon][this.datosPedidos[i].id_per_v_suc][n] = Number((this.datosPedidos[i].notas[n].cantidad).toFixed(2))
              }
            }
          } else if (this.separaciones.vendedor) {
            if (!this.datosPedidosIm[this.datosPedidos[i].id_per_v_suc]) {
              this.datosPedidosIm[this.datosPedidos[i].id_per_v_suc] = {}
              this.datosPedidosIm[this.datosPedidos[i].id_per_v_suc].ven = this.vendedores[this.datosPedidos[i].id_per_v_suc].nom_per + ' ' + this.vendedores[this.datosPedidos[i].id_per_v_suc].ape_per
            }
            for (const n in this.datosPedidos[i].notas) {
              if (this.datosPedidosIm[this.datosPedidos[i].id_per_v_suc][n]) {
                this.datosPedidosIm[this.datosPedidos[i].id_per_v_suc][n] += Number((this.datosPedidos[i].notas[n].cantidad).toFixed(2))
              } else {
                this.datosPedidosIm[this.datosPedidos[i].id_per_v_suc][n] = Number((this.datosPedidos[i].notas[n].cantidad).toFixed(2))
              }
            }
          } else {
            for (const n in this.datosPedidos[i].notas) {
              if (this.datosPedidosIm[n]) {
                this.datosPedidosIm[n] += Number((this.datosPedidos[i].notas[n].cantidad).toFixed(2))
              } else {
                this.datosPedidosIm[n] = Number((this.datosPedidos[i].notas[n].cantidad).toFixed(2))
              }
            }
          }
          for (const n in this.datosPedidos[i].notas) {
            if (!this.datosPedidosIm.cate[this.presentaciones[n].id_cat_pro]) {
              this.datosPedidosIm.cate[this.presentaciones[n].id_cat_pro] = {
                nomCat: this.categorias[this.presentaciones[n].id_cat_pro].nom_cat,
                numCat: 0,
                nomCli: [],
                act: true
              }
            }
            if (!this.datosPedidosIm.cate[this.presentaciones[n].id_cat_pro].nomCli.includes(this.datosPedidos[i].rif_emp)) {
              this.datosPedidosIm.cate[this.presentaciones[n].id_cat_pro].numCat += 1
              this.datosPedidosIm.cate[this.presentaciones[n].id_cat_pro].nomCli.push(this.datosPedidos[i].rif_emp)
            }
          }
        }
      }
      this.productos = []
      let totalCanCat = 0
      let totalPeCat = 0
      let totalCanVen = 0
      let totalPeVen = 0
      let totalCanZon = 0
      let totalPeZon = 0
      if (this.separaciones.estado) {
        for (const z in this.datosPedidosIm) {
          let catPro = ''
          let estPro = ''
          let venPro = ''
          if (this.separaciones.categoria) {
            for (const cat in this.categorias) {
              for (const v in this.datosPedidosIm[z]) {
                for (const pro in this.categorias[cat].productos) {
                  for (const pre in this.categorias[cat].productos[pro].presentaciones) {
                    for (const p in this.datosPedidosIm[z][v]) {
                      if (!isNaN(this.datosPedidosIm[z][v][p]) && this.categorias[cat].productos[pro].presentaciones[pre].id_pre === p) {
                        if (venPro !== this.datosPedidosIm[z][v].ven && totalCanVen > 0) {
                          this.productos.push(
                            {
                              totalCanVen: totalCanVen,
                              totalPeVen: totalPeVen
                            }
                          )
                          totalCanVen = 0
                          totalPeVen = 0
                        }
                        if (catPro !== this.categorias[cat].nom_cat && totalCanCat > 0) {
                          this.productos.push(
                            {
                              totalCanCat: totalCanCat,
                              totalPeCat: totalPeCat
                            }
                          )
                          totalCanCat = 0
                          totalPeCat = 0
                        }
                        totalPeCat += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))
                        totalPeVen += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))
                        totalPeZon += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))
                        totalCanCat += this.datosPedidosIm[z][v][p]
                        totalCanVen += this.datosPedidosIm[z][v][p]
                        totalCanZon += this.datosPedidosIm[z][v][p]
                        this.productos.push(
                          {
                            descripcion: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.des_pre.toUpperCase(),
                            codigo: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.cod_pre,
                            unidades: Number((this.datosPedidosIm[z][v][p]).toFixed(2)),
                            kilos: `${Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))} KG`,
                            cat: catPro !== this.categorias[cat].nom_cat ? `${catPro = this.categorias[cat].nom_cat}` : false,
                            est: estPro !== this.datosPedidosIm[z].est ? `${estPro = this.datosPedidosIm[z].est}` : false,
                            ven: venPro !== this.datosPedidosIm[z][v].ven ? `${venPro = this.datosPedidosIm[z][v].ven}` : false
                          }
                        )
                      }
                    }
                  }
                }
              }
            }
          } else {
            for (const v in this.datosPedidosIm[z]) {
              for (const cat in this.categorias) {
                for (const pro in this.categorias[cat].productos) {
                  for (const pre in this.categorias[cat].productos[pro].presentaciones) {
                    for (const p in this.datosPedidosIm[z][v]) {
                      if (!isNaN(this.datosPedidosIm[z][v][p]) && this.categorias[cat].productos[pro].presentaciones[pre].id_pre === p) {
                        if (venPro !== this.datosPedidosIm[z][v].ven && totalCanVen > 0) {
                          this.productos.push(
                            {
                              totalCanVen: totalCanVen,
                              totalPeVen: totalPeVen
                            }
                          )
                          totalCanVen = 0
                          totalPeVen = 0
                        }
                        totalPeVen += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))
                        totalPeZon += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))
                        totalCanVen += this.datosPedidosIm[z][v][p]
                        totalCanZon += this.datosPedidosIm[z][v][p]
                        this.productos.push(
                          {
                            descripcion: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.des_pre.toUpperCase(),
                            codigo: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.cod_pre,
                            unidades: Number((this.datosPedidosIm[z][v][p]).toFixed(2)),
                            kilos: `${Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[z][v][p])).toFixed(2))} KG`,
                            cat: catPro !== this.categorias[cat].nom_cat ? `${catPro = this.categorias[cat].nom_cat}` : false,
                            est: estPro !== this.datosPedidosIm[z].est ? `${estPro = this.datosPedidosIm[z].est}` : false,
                            ven: venPro !== this.datosPedidosIm[z][v].ven ? `${venPro = this.datosPedidosIm[z][v].ven}` : false
                          }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          if (totalCanVen > 0) {
            this.productos.push(
              {
                totalCanVen: totalCanVen,
                totalPeVen: totalPeVen
              }
            )
            totalCanVen = 0
            totalPeVen = 0
          }
          if (totalCanCat > 0) {
            this.productos.push(
              {
                totalCanCat: totalCanCat,
                totalPeCat: totalPeCat
              }
            )
            totalCanCat = 0
            totalPeCat = 0
          }
          if (totalCanZon > 0) {
            this.productos.push(
              {
                totalCanEst: totalCanZon,
                totalPeEst: totalPeZon
              }
            )
            totalCanZon = 0
            totalPeZon = 0
          }
        }
      } else if (this.separaciones.vendedor) {
        for (const v in this.datosPedidosIm) {
          let catPro = ''
          let venPro = ''
          for (const cat in this.categorias) {
            for (const pro in this.categorias[cat].productos) {
              for (const pre in this.categorias[cat].productos[pro].presentaciones) {
                for (const p in this.datosPedidosIm[v]) {
                  if (!isNaN(this.datosPedidosIm[v][p]) && this.categorias[cat].productos[pro].presentaciones[pre].id_pre === p) {
                    if (catPro !== this.categorias[cat].nom_cat && totalCanCat > 0) {
                      this.productos.push(
                        {
                          totalCanCat: totalCanCat,
                          totalPeCat: totalPeCat
                        }
                      )
                      totalCanCat = 0
                      totalPeCat = 0
                    }
                    if (venPro !== this.datosPedidosIm[v].ven && totalCanVen > 0) {
                      this.productos.push(
                        {
                          totalCanVen: totalCanVen,
                          totalPeVen: totalPeVen
                        }
                      )
                      totalCanVen = 0
                      totalPeVen = 0
                    }
                    totalPeCat += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[v][p])).toFixed(2))
                    totalPeVen += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[v][p])).toFixed(2))
                    totalCanCat += this.datosPedidosIm[v][p]
                    totalCanVen += this.datosPedidosIm[v][p]
                    this.productos.push(
                      {
                        descripcion: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.des_pre.toUpperCase(),
                        codigo: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.cod_pre,
                        unidades: Number((this.datosPedidosIm[v][p]).toFixed(2)),
                        kilos: `${Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[v][p])).toFixed(2))} KG`,
                        cat: catPro !== this.categorias[cat].nom_cat ? `${catPro = this.categorias[cat].nom_cat}` : false,
                        ven: venPro !== this.datosPedidosIm[v].ven ? `${venPro = this.datosPedidosIm[v].ven}` : false
                      }
                    )
                  }
                }
              }
            }
          }
        }
        if (totalCanCat > 0) {
          this.productos.push(
            {
              totalCanCat: totalCanCat,
              totalPeCat: totalPeCat
            }
          )
          totalCanCat = 0
          totalPeCat = 0
        }
        if (totalCanVen > 0) {
          this.productos.push(
            {
              totalCanVen: totalCanVen,
              totalPeVen: totalPeVen
            }
          )
        }
      } else {
        let catPro = ''
        for (const cat in this.categorias) {
          for (const pro in this.categorias[cat].productos) {
            for (const pre in this.categorias[cat].productos[pro].presentaciones) {
              for (const p in this.datosPedidosIm) {
                if (this.categorias[cat].productos[pro].presentaciones[pre].id_pre === p) {
                  totalPeCat += Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[p])).toFixed(2))
                  totalCanCat += this.datosPedidosIm[p]
                  this.productos.push(
                    {
                      descripcion: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.des_pre.toUpperCase(),
                      codigo: this.categorias[cat]?.productos[pro]?.presentaciones[pre]?.cod_pre,
                      unidades: Number((this.datosPedidosIm[p]).toFixed(2)),
                      kilos: `${Number((Number(this.presentaciones[pre].pesm_pre) * Number(this.datosPedidosIm[p])).toFixed(2))} kg`,
                      cat: catPro !== this.categorias[cat].nom_cat ? `${catPro = this.categorias[cat].nom_cat}` : false
                    }
                  )
                }
              }
            }
          }
          if (totalCanCat > 0) {
            this.productos.push(
              {
                totalCanCat: totalCanCat,
                totalPeCat: totalPeCat
              }
            )
            totalCanCat = 0
            totalPeCat = 0
          }
        }
      }
    },
    imprimir () {
      window.print()
    }
  },
  mounted () {
    this.ordenDeSalida()
  }
}
</script>

<style lang="scss" scoped>
$tabla-separador: rgba(0, 0, 0, 0.05);
  .table.orden {
    @media screen {
      @include ul-table-responsive-at ("576px");
    }
    .tabla-separador {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem !important;
      color: $gris-muted;
      background-color: #fafafa;
    }
  }
  .titulo-impresion {
    font-size: 1.5rem;
    font-weight: 400;
  }
  .fs-9 {
    font-size: .8rem;
    .titulo-impresion {
      font-size: 1.2rem;
    }
  }
  .bold {font-weight: 400;}
  footer {
    align-items: center;
    border-top: 1px solid $tabla-separador;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: .75rem 0;
    margin: auto;

    &.footer-fixed {
      bottom: 0;
      position: sticky;
      width: 100%;
      background: white;
      z-index: 3;
    }
  }
  @media print {
    .table.orden {
      li.contenido {
        p {
          font-size: .7rem;
        }
      }
    }
    .col-custom {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding: 0 .75rem;
    }
    .py-print-0 {
      padding-top: .2rem !important;
      padding-bottom: .2rem !important;
    }
    p {
      margin-bottom: .2rem;
    }
    * {
      color: black !important;
    }
  }
</style>
